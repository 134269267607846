import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import { Box, Section } from '@nib/layout';

import Layout from '../../components/Layout';
import { ContentfulDocument } from '../../constructs/models';
import metrics from '../../metrics';
import IframeResizer from 'iframe-resizer-react';
import config from '../../utils/env';

import LifeLivingInsuranceSection, {
  LifeLivingInsuranceSectionProps,
} from '../../page-sections/life-living-insurance-section';
import { ContentHeroPanelProps } from '../../components/ContentHeroPanel';
import CoupleHuggingImage from '../../img/life-and-living/couple-hugging.jpeg';
import { NavContentProps, NavLinkProps } from '../../components/NavContent';
import { ImportantInformationBannerProps } from '../../components/ImportantInformationBanner';
import Link from '@nib-components/link';
import { ExternalLinkSystemIcon } from '@nib/icons';
import { urlConstants } from '../../constructs/constants';
import { getValue } from '../../hooks/useSalesforceForm';

const title = 'Life & Living Insurance Quote';
const description =
  'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.';

const contentHeroData: ContentHeroPanelProps = {
  title: (
    <>
      Let’s talk about <span style={{ color: '#82E578' }}>Life & Living insurance.</span>
    </>
  ),
  subtextLeft: 'LIFE INSURANCE',
  subtextRight: 'LIVING INSURANCE',
  description: <>It’s healthy to talk about death. It’s even better to plan for it.</>,
  imageMobile: CoupleHuggingImage,
  imageDesktop: CoupleHuggingImage,
  imageAlt:
    "A man and woman smile joyfully while seated together at a table, enjoying each other's company.",
  type: 'banner',
};

const navigationLinks: NavLinkProps[] = [
  {
    text: 'About Life & Living insurance',
    isActive: false,
    href: urlConstants.aboutLifeLivingInsurance,
  },
  {
    text: 'Special offers',
    isActive: false,
    href: urlConstants.currentOfferLifeLivingInsurance,
  },
  {
    text: 'Why get Life & Living insurance',
    isActive: false,
    href: urlConstants.whyLifeLivingInsuranceCoverIsImportant,
  },
  {
    text: 'Estimator Tool',
    isActive: true,
    href: urlConstants.estimateYourCoverageLifeLivingInsurance,
  },
  {
    text: 'For homeowners',
    isActive: false,
    href: urlConstants.lifeLivingHomeowners,
  },
  { text: 'How to apply', isActive: false, href: urlConstants.howToApplyLifeLivingInsurance },
];

const navigation: NavContentProps = {
  title: 'Life & Living insurance',
  homeHref: urlConstants.compareLifeAndLivingInsurance,
  primaryButton: { text: 'Apply now', href: urlConstants.estimateYourCoverageLifeLivingInsurance },
  secondaryButton: { text: 'Contact us', href: urlConstants.lifeLivingInsuranceContactUs },
  navigation: navigationLinks,
};

const importantInformationData: ImportantInformationBannerProps = {
  title: 'Important Information',
  description: [
    <Copy measure={false} size="small">
      Only the main features and benefits of the Life & Living Insurance is outlined here. For a
      full explanation of all benefits, exclusions and other terms and conditions of the cover, view
      the{' '}
      <Link href={urlConstants.lifeAndLivingInsuranceWording} icon={ExternalLinkSystemIcon}>
        Life & Living Insurance wording
      </Link>
      .
    </Copy>,
    <Copy measure={false} size="small">
      Life & Living Insurance is provided by nib nz insurance limited and is a related company of
      nib nz limited (“nib”). nib nz insurance limited issues and underwrites Life & Living
      Insurance products, and is solely responsible for claims under the cover. nib sells and may
      advise you on these products. Find out more about{' '}
      <Link href={urlConstants.financialStrength}>
        financial strength and financial advice with nib
      </Link>
      .
    </Copy>,
  ],
};

const lifeLivingSectionData: LifeLivingInsuranceSectionProps = {
  heroContent: contentHeroData,
  navigation: navigation,
  importantInformationData: importantInformationData,
};

const content = {
  heading: 'Estimate your cover',
  body: (
    <>
      Unsure what Life insurance or Living insurance cover you need? We make it easy with our
      estimator tool. It walks you through some simple questions about your current lifestyle, then
      gives you information on the type of cover that may be right for you, how much cover you could
      get, and what it might cost.​ <br />
      <br />
      After you get your estimate, you can take the next steps to apply or ask a specialist.
    </>
  ),
};

type DocumentResult = {
  edges: {
    node: {
      document: ContentfulDocument;
    };
  }[];
};

interface ImageShape {
  file: {
    url: string;
  };
}

interface HtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
  };
}
interface RawAndHtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
    rawMarkdownBody: string;
  };
}

interface HeroImagesShape {
  desktop: ImageShape;
  desktop2x4000x1200: ImageShape;
  mobile2x800x300: ImageShape;
  mobile400x150: ImageShape;
  tablet2x1600x580: ImageShape;
  tablet800x290: ImageShape;
}

interface NodeShape {
  title: string;
  startDate: string;
  heroTitle: string;
  heroCopy: RawAndHtmlMarkdownShape;
  leftButtonText: string;
  rightButtonText: string;
  legal: RawAndHtmlMarkdownShape;
  campaignCode: string;
  heroImage: HeroImagesShape;
  importantThingsToKnow: HtmlMarkdownShape;
}

type LifeCoverOptionsPageResult = {
  edges: {
    node: NodeShape;
  }[];
};

type GraphQLResult = {
  temporaryAccidentalDeathCover: DocumentResult;
  lifePolicyDocument: DocumentResult;
  factSheetUrl: DocumentResult;
  lifeCoverOptionsPage: LifeCoverOptionsPageResult;
};

interface LifeCoverOptionsProps {
  data: GraphQLResult;
}

const LifeAndLivingEstimateYourCoverage = ({ data }: LifeCoverOptionsProps): JSX.Element => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/life-and-living/estimate-your-cover',
    url: 'https://www.nib.co.nz/life-and-living/estimate-your-cover',
    description:
      'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.',
    name: ['Life & Living Insurance Quote | nib'],
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <LifeLivingInsuranceSection {...lifeLivingSectionData}>
        <MainContent data={data} />
      </LifeLivingInsuranceSection>
    </Layout>
  );
};

const MainContent = ({ data }: LifeCoverOptionsProps) => {
  return (
    <Section role="section">
      <EstimateYourCoverage />
      <LifeAndLivingEstimatorTool data={data} />
    </Section>
  );
};

const EstimateYourCoverage = (): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" gap="4" padding="3">
      <Box display="flex" justifyContent={{ xs: 'center', xl: 'start' }}>
        <Heading color="trueGreen" size={{ xs: 2 }} component="h2">
          {content.heading}
        </Heading>
      </Box>
      <Box display="flex" justifyContent="center">
        <Copy measure={false} color="deepBlack">
          {content.body}
        </Copy>
      </Box>
    </Box>
  );
};

const LifeAndLivingEstimatorTool = ({ data }: LifeCoverOptionsProps): JSX.Element => {
  const [referralCode, setReferralCode] = useState('');
  const isBrowser = () => typeof window !== 'undefined';

  useEffect(() => {
    if (isBrowser()) {
      const ref = getValue('referer');

      if (window.location.search.startsWith('?ref=') && window.location.search.length > 5) {
        setReferralCode(window.location.search.substring(5));
      } else if (ref) {
        setReferralCode(ref);
      } else {
        setReferralCode('');
      }
    }
  }, [isBrowser() && window.location.search]);

  return (
    <IframeResizer
      src={
        referralCode.length === 0
          ? config.lifeEstimatorUrl
          : `${config.lifeEstimatorUrl}?ref=${referralCode}`
      }
      style={{ width: '1px', minWidth: '100%', border: 'none' }}
      scrolling={false}
      onScroll={() => true}
      heightCalculationMethod="lowestElement"
    />
  );
};

export default metrics({ pageName: 'life-and-living-estimate-your-coverage' })(
  LifeAndLivingEstimateYourCoverage
);
